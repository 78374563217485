<template>
  <div class="phonebox" style="">
    <div class="phonetitle">领用记录</div>

    <div class="phoneMain">
      <el-table :data="list" border style="width: 100%;margin-top: 20px;">
        <el-table-column prop="consumable.name" label="名称" min-width="10%"></el-table-column>
        <el-table-column prop="staff_name" label="领用人" min-width="10%"></el-table-column>
        <el-table-column prop="created_at" label="领用时间" min-width="10%"></el-table-column>
        <el-table-column prop="receive_nums" label="领用数量" min-width="10%"></el-table-column>

      </el-table>
      <div class="pagbox">
        <el-pagination :pager-count='5' @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="page" :page-sizes="[5,10, 50, 100]" :page-size="limit" layout="sizes, prev, pager, next" :total="total">
        </el-pagination>
      </div>

    </div>

    <div class="phoneFoot">
      <div class="footbox ">
        <router-link :to="{name:'phoneProductionOrder'}"> 生产订单</router-link>
      </div>
      <div class="footbox ">
        <router-link :to="{name:'phoneSchedule'}"> 生产排程</router-link>
      </div>
      <div class="footbox">
        <router-link :to="{name:'home'}"> 实时看板</router-link>
      </div>
      <div class="footbox">
        <router-link :to="{name:'phoneReport'}"> 设备报表</router-link>
      </div>
      <div class="footbox">
        <router-link :to="{name:'phoneStaffreport'}"> 员工报表</router-link>
      </div>
      <div class="footbox active">

        <router-link :to="{name:'phoneconsu'}"> 耗材库存</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { logs } from "@/service/api";
export default {
  data () {
    return {
      list: [],
      page: 1,
      limit: 10,
      queritem: '',
      status: '',
      name: '',
      deviceList: [],
      iot_id: '',
      type_id: '',
      types: [],
      id: ''
    };
  },
  methods: {
    xalert () {
      alert('开发中');
    },
    linyong (row) {
      console.log(row);
      this.$router.push('lylist?id=' + row.id);
    },
    swiot_id (row) {
      let text = '';

      this.deviceList.forEach(item => {
        if (item.iot_id === row.iot_id) {
          text = item.nick_name;
        }

      });
      return text;
    },
    cx () {
      this.page = 1;
      this.getlist();
    },
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`);
      this.limit = val;
      this.getlist();
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`);
      this.page = val;
      this.getlist();
    },
    getlist () {
      let data = { limit: this.limit, page: this.page, id: this.id };
      logs(data).then(res => {
        console.log(res);
        // res.data.list.forEach(item => {
        //   item.order_date = item.order_date ? this.dayjs(item.order_date).format('YYYY-MM-DD') : '无';
        //   item.delivery_date = item.delivery_date ? this.dayjs(item.delivery_date).format('YYYY-MM-DD') : '无';
        // });
        this.list = res.data.list;
        this.total = res.data.total;
      });
    }
  },
  mounted () {
    this.id = this.$route.query.id;
    this.getlist();

  }
};
</script>
<style scoped>
@import './css/index.css';
</style>